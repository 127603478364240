<template>
  <div>
    <apexchart
        :options="options"
        :series="series"
        type="donut"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "Chart",
  props: ['options', 'series']
}
</script>