<template>
    <v-app>
        <div>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-9">
                            <div class=" card  card-stretch gutter-b">
                                <div class="card-body">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <div class="card card-custom">
                                                <div class="card-body rounded p-0 d-flex bg-light">
                                                    <div class="d-flex flex-column flex-lg-row-auto w-100 py-2 py-md-2 px-2 px-md-2 pr-lg-2">

                                                        <form @submit.prevent="searchStudent"
                                                              class="d-flex flex-center py-2 px-6 bg-white rounded">
                                                              <span class="svg-icon svg-icon-lg svg-icon-primary">
                                                                <i class="fas fa-search"></i>
                                                              </span>
                                                            <v-text-field
                                                                    v-on:keyup.enter="searchStudent"
                                                                    dense
                                                                    flat
                                                                    label="Search By Name, Email or Phone, or Business email"
                                                                    lass="form-control border-0 font-weight-bold pl-2"
                                                                    placeholder="Search By Name, Email or Phone, or Business email"
                                                                    solo
                                                                    type="text"
                                                                    v-model="search.name"
                                                            ></v-text-field>
                                                        </form>
                                                        <!--end::Form-->
                                                    </div>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <div class="card card-custom">
                                                <div class="card-body rounded p-0 d-flex bg-light">
                                                    <div class="d-flex flex-column flex-lg-row-auto w-100 py-2 py-md-2 px-2 px-md-2 pr-lg-2">
                                                        <form @submit.prevent="searchApplication"
                                                              class="d-flex flex-center py-2 px-6 bg-white rounded">
                                                              <span class="svg-icon svg-icon-lg svg-icon-primary">
                                                                <i class="fas fa-search"></i>
                                                              </span>
                                                              <v-text-field
                                                                      v-on:keyup.enter="searchApplication"
                                                                      dense
                                                                      flat
                                                                      label="Search By application reference ID"
                                                                      lass="form-control border-0 font-weight-bold pl-2"
                                                                      placeholder="Search By application reference ID"
                                                                      solo
                                                                      type="text"
                                                                      v-model="application.reference_id"
                                                              ></v-text-field>
                                                        </form>
                                                        <!--end::Form-->
                                                    </div>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row class="text-center">
                                        <v-col class="text-center" cols="4" md="3"
                                               sm="4" v-if="checkIsAccessible('institution', 'list')">
                                            <router-link :to="{ name: 'institutions' }">
                                                <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                                                    <img src="https://cdn-icons-png.flaticon.com/512/3720/3720643.png"
                                                         style="height: 64px"/>
                                                    <p>Institution</p>
                                                    <v-divider></v-divider>
                                                    <strong>
                                                      {{countInstitutions}}
                                                    </strong>
                                                </div>
                                            </router-link>
                                        </v-col>
                                        <v-col class="text-center" cols="4" md="3" sm="4" v-if="checkIsAccessible('academic-program', 'list')">
                                            <router-link :to="{ name: 'program' }">
                                                <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                                                    <img src="media/users/programs.png" style="height: 64px"/>
                                                    <p>Programs</p>
                                                    <v-divider></v-divider>
                                                    <strong>
                                                      {{countAcademicPrograms}}
                                                    </strong>
                                                </div>
                                            </router-link>
                                        </v-col>

                                        <v-col class="text-center" cols="4" md="3" sm="4" v-if="checkIsAccessible('application', 'list')">
                                            <router-link :to="{ name: 'user-student' }">
                                                <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                                                    <img src="media/users/applicants.png" style="height: 64px"/>
                                                    <p>Total Applicants</p>
                                                    <v-divider></v-divider>
                                                    <strong>
                                                      {{total_applicants}}
                                                    </strong>
                                                </div>
                                            </router-link>
                                        </v-col>
                                        <v-col class="text-center" cols="4" md="3" sm="4" v-if="checkIsAccessible('agent', 'list')">
                                            <router-link :to="{ name: 'user-agent' }">
                                                <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                                                    <img src="media/users/agents.png" style="height: 64px"/>
                                                    <p>Total Agent</p>
                                                    <v-divider></v-divider>
                                                    <strong>
                                                      {{totalAgentCount}}
                                                    </strong>
                                                </div>
                                            </router-link>
                                        </v-col>
                                        <v-col class="text-center" cols="4" md="3" sm="4" v-if="checkIsAccessible('user', 'list')">
                                            <router-link :to="{ name: 'user-student' }">
                                                <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                                                    <img src="media/users/students.png" style="height: 64px"/>
                                                    <p>Total Students</p>
                                                    <v-divider></v-divider>
                                                    <strong>
                                                      {{countStudent}}
                                                    </strong>
                                                </div>
                                            </router-link>
                                        </v-col>
                                        <v-col class="text-center" cols="4" md="3" sm="4" v-if="checkIsAccessible('application', 'list')">
                                            <router-link :to="{ name: 'applications',  params: { type:'academic', status: 'draft' } }">
                                                <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                                                    <img src="media/users/applications.png" style="height: 64px"/>
                                                    <p>Total Applications</p>
                                                    <v-divider></v-divider>
                                                    <strong>
                                                      {{total_application}}
                                                    </strong>
                                                </div>
                                            </router-link>
                                        </v-col>
                                        <v-col class="text-center" cols="4" md="3" sm="4" v-if="checkIsAccessible('agent', 'list')">
                                            <router-link :to="{ name: 'user-request-for-agent' }">
                                                <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                                                    <img src="media/users/agent.png" style="height: 64px"/>
                                                    <p>Agent Requests</p>
                                                    <v-divider></v-divider>
                                                    <strong>
                                                        {{countAgentRequests}}
                                                    </strong>
                                                </div>
                                            </router-link>
                                        </v-col>
                                        <v-col class="text-center" cols="4" md="3" sm="4" v-if="checkIsAccessible('task-manager', 'list')">
                                            <router-link :to="{ name: 'task-manager' }">
                                                <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                                                    <img src="media/education/icons/enrolments.png" style="height: 64px"/>
                                                    <p>Task</p>
                                                    <v-divider></v-divider>
                                                    <strong>
                                                      {{applied_by + applied_to}}
                                                    </strong>
                                                </div>
                                            </router-link>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card card-custom gutter-b card-stretch">
                                <!--begin::Header-->
                                <div class="card-header border-0">
                                    <div class="card-toolbar">
                                        <div class="dropdown dropdown-inline">
                                            <v-select
                                                    :items="countries"
                                                    clearable
                                                    dense
                                                    item-text="title"
                                                    label="Country "
                                                    outlined return-object
                                                    v-model="selected_country"
                                            ></v-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body pt-2">
                                    <template>
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                                              <img src="media/education/icons/academic-year.png" style="height: 64px"/>
<!--                                                <div class="symbol-label"-->
<!--                                                     style="background-image:url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQ9hb5h1fVboRAu654wqf69ANRCzok0n-T5ScjyVvYL2B-QowHjUEDT7E2seaFI8zWK2k&usqp=CAU)"></div>-->
                                            </div>

                                            <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                                                <a class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                                                   href="#">
                                                    Total Institutions
                                                </a>

                                            </div>
                                            <div class="d-flex align-items-center py-lg-0 py-2">
                                                <div class="d-flex flex-column text-right"><span
                                                        class="text-dark-75 font-weight-bolder font-size-h5">{{ selected_country ? selected_country.instutions : 0 }}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </template>
                                  <template>
                                    <div class="d-flex flex-wrap align-items-center mt-2">
                                      <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                                        <img src="media/education/icons/programs-flat.png" style="height: 64px"/>
                                      </div>

                                      <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                                        <a class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                                           href="#">
                                          Total Programs
                                        </a>

                                      </div>
                                      <div class="d-flex align-items-center py-lg-0 py-2">
                                        <div class="d-flex flex-column text-right"><span
                                                class="text-dark-75 font-weight-bolder font-size-h5">{{ selected_country ? selected_country.programs : 0 }}</span>
                                        </div>
                                      </div>
                                    </div>

                                    </template>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <div class=" card  card-stretch gutter-b">
                        <div class="card-body">
                          <v-row>
                            <v-col cols="12">
                              <div class="card card-custom">
                                <div class="card-body rounded p-0 d-flex bg-light">
                                  <div class="d-flex flex-column flex-lg-row-auto w-100 py-2 py-md-2 px-2 px-md-2 pr-lg-2">
                                    <h3>My Leads</h3>
                                  </div>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row class="text-center"  v-if="leadCategories.length > 0">
                            <v-col class="text-center" cols="4" md="3" sm="4" v-for="(lead, index) in leadCategories" :key="index">
                              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                                <p>{{ lead.name }}</p>
                                <v-divider></v-divider>
                                <strong>
                                  {{lead.lead_count}}
                                </strong>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row class="text-center"  v-else>
                            <v-col class="text-center" cols="12">
                              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                                <strong>
                                  No Lead available
                                </strong>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card card-stretch gutter-b">
                        <div class="card-body">
                          <v-row class="">
                            <v-col cols="12">
                              <div class="card card-custom">
                                <div class="card-body rounded p-0 d-flex bg-light">
                                  <div class="d-flex flex-column flex-lg-row-auto w-100 py-2 py-md-2 px-2 px-md-2 pr-lg-2">
                                    <h3>Leads summary</h3>
                                  </div>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row class="text-center" v-if="options && series.length > 0">
                            <v-col class="text-center" cols="12">
                              <chart :options="options" :series="series"></chart>
                            </v-col>
                          </v-row>
                          <v-row class="text-center"  v-else>
                            <v-col class="text-center" cols="12">
                              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                                <strong>
                                  No Lead Summary Found
                                </strong>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">

                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">

                </div>
            </div>
            <!--end::Dashboard-->
        </div>
    </v-app>

</template>

<script>
import {mapActions} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import StateService from "@/services/country/state/StateService";
import DashboardService from "@/services/dashboard/DashboardService";
import CountryService from "@/services/country/CountryService";
import InstitutionService from "@/services/academic/institution/InstitutionService";
import AcademicProgramService from "@/services/academic/program/AcademicProgramService";
import UserService from "@/services/user/UserService";
import TaskService from "../../services/task-manager/task/TaskService";
import LeadCategoryService from "@/services/enquiry/lead/category/LeadCategoryService";
import Chart from "@/view/pages/view/Chart";

const task=new TaskService();
const state = new StateService();
const dashboard = new DashboardService();
const country = new CountryService();
const institution = new InstitutionService();
const academicProgram = new AcademicProgramService();
const user = new UserService();
const leadCategory = new LeadCategoryService();
export default {
    name: "dashboard",
  components: {Chart},
  data() {
        return {
            stats: {},
            countInstitutions: 0,
            countAcademicPrograms: 0,
            totalApplicants: 0,
            totalApplications: 0,
            totalAgentCount: 0,
            countStudent: 0,
            countAgentRequests: 0,
            selected_country: null,
            programs: [],
            leadCategories: [],
            options: {
              labels: []
            },
            series: [],
            search: {
                name: '',
                type: 'student',
                limit: 25,

            },
            filter: null,
            states: [],
            countries: [],
            application: {
              reference_id: ''
            },
          applied_by:'',
          applied_to:'',
        };
    },
    computed: {
        user() {
            return this.$store.getters.currentUser;
        },

        total_application(){
            return this.$store.getters.getTotalApplications
        },
        total_applicants(){
            return this.$store.getters.getTotalApplicants
        }
    },
    methods: {

        getCountries() {
            country
                .getAllCountry({'is_operating_country': 1})
                .then((response) => {
                    this.countries = response.data.data;
                })
                .catch((error) => {
                })
        },

        getCountInstitution() {
          institution.countInstitution().then(response => {
            this.countInstitutions = response.data.count;
          }).catch(() => {});
        },

        getCountAcademicProgram() {
          academicProgram.countAcademicProgram().then(response => {
            this.countAcademicPrograms = response.data.count;
          }).catch(() => {});
        },

        getDashboardStats() {
          dashboard.getDashboardStats().then(response => {
            this.countStudent = response.data.totalStudentCount;
            this.countAgentRequests = response.data.totalAgentRequestCount;
            this.totalAgentCount = response.data.totalAgentCount;
          }).catch(() => {});
        },

        searchStudent(){
          this.$router.push({
              name:'search',
              query:this.search
          })
        },

        searchApplication(){
          this.$router.push({
              name:'search-application',
              params: { type: 'academic'},
              query: this.application
          })
        },

        getAppliedBy(){
          task
          .countApplyBy()
          .then(response=>{
              this.applied_by=response.data.count;
          })
              .catch(() => {});
          },

      getAppliedTo(){
        task
        .countApplyTo()
            .then(response=>{
              this.applied_to=response.data.count;
            })
            .catch(() => {});
      },

        setActiveTab(event) {
            // get all tab links
            const tab = event.target.closest('[role="tablist"]');
            const links = tab.querySelectorAll(".nav-link");
            // remove active tab links
            for (let i = 0; i < links.length; i++) {
                links[i].classList.remove("active");
            }

            // set current active tab
            event.target.classList.add("active");

            // set clicked tab index to bootstrap tab
            return parseInt(event.target.getAttribute("data-tab"));
        },

        getCategoryStat() {
          leadCategory.getCategoryStat().then(response => {
                this.leadCategories = response.data.leadCategories.stat
                this.options.labels = response.data.leadCategories.name
                this.series = response.data.leadCategories.count
            });
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{title: "Dashboard"}]);
        this.getCountInstitution();
        this.getCountAcademicProgram();
        this.getCountries();
        this.getAppliedBy();
        this.getAppliedTo();
        this.getCategoryStat();
        this.getDashboardStats();
    },

};
</script>

<style scoped>
    .theme--light.v-application {
        background: none !important;
        /*color: rgba(0, 0, 0, 0.87);*/
    }
</style>

